<template>
  <div
    class="mx-auto flex max-w-6xl flex-wrap items-center justify-center overflow-visible px-0 md:overflow-hidden md:px-6 lg:flex-nowrap"
  >
    <div
      class="w-full px-8 py-10 md:-ml-16 md:mr-16 md:w-1/2 md:p-0 md:py-4 xl:mx-0 xl:mr-24"
    >
      <h1 class="mb-4 text-2xl md:text-3xl" :class="headingColor">
        {{ $t('home.info.title') }}
      </h1>
      <div class="md:text-lg">
        <p class="mb-4">
          <span>{{ $t('home.info.primary') }}{{ ' ' }}</span>
          <span v-for="(link, index) in primaryLinks" :key="index">
            <NuxtLink
              :to="$rt(link.path)"
              class="text-primary-default underline"
              :class="underline ? 'underline' : ''"
            >
              {{ $rt(link.name) }}</NuxtLink
            >{{ getItemSeparator(primaryLinks.length, index) }}{{ ' ' }}
          </span>
        </p>
        <p>
          <span>{{ $t('home.info.secondary') }}{{ ' ' }}</span>
          <span v-for="(link, index) in secondaryLinks" :key="link.name">
            <NuxtLink
              :to="$rt(link.path)"
              class="text-primary-default underline"
              :class="underline ? 'underline' : ''"
              >{{ $rt(link.name) }}</NuxtLink
            >{{ getItemSeparator(secondaryLinks.length, index) }}{{ ' ' }}
          </span>
        </p>
      </div>
    </div>
    <img
      :src="StartPageWelcomeImage"
      class="order-first size-full md:order-last md:-mr-32 md:max-h-full md:w-1/2 xl:mr-0"
      loading="lazy"
    />
  </div>
</template>

<script setup lang="ts">
import StartPageWelcomeImage from '~/assets/images/startpage-welcome-image.webp?url';

const { t, tm } = useI18n();

defineProps({
  underline: {
    type: Boolean,
    default: false,
  },
  headingColor: {
    type: String,
    default: 'text-black/87',
  },
});

// Explicitly type cast $tm as array of objects
const primaryLinks = tm('home.info.primary_links') as Array<{
  path: string;
  name: string;
}>;
const secondaryLinks = tm('home.info.secondary_links') as Array<{
  path: string;
  name: string;
}>;

const getItemSeparator = (arrayLength: number, index: number) => {
  // Second last
  if (index === arrayLength - 2) {
    return ` ${t('and')} `;
  }

  // Last
  if (index === arrayLength - 1) {
    return '.';
  }

  return ',';
};
</script>
